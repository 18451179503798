import React from "react";
import { Menu } from "antd";
import { useNavigate } from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import propTypes from "prop-types";
import { useTranslation } from "react-i18next";

const MenuItems = ({ darkMode, toggleCollapsed, topMenu, events }) => {
  const path = "/"; //[ds] fix this
  const { t } = useTranslation(["admin_layout"]);

  const pathName = window.location.pathname;
  const pathArray = pathName.split(path);
  const mainPath = pathArray[1];
  const mainPathSplit = mainPath.split("/");

  let nav = useNavigate();

  const [openKeys, setOpenKeys] = React.useState(
    !topMenu
      ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : "dashboard"}`]
      : []
  );

  const onOpenChange = (keys) => {
    setOpenKeys(
      keys[keys.length - 1] !== "recharts"
        ? [keys.length && keys[keys.length - 1]]
        : keys
    );
  };

  const onClick = (item) => {
    if (item.keyPath.length === 1) setOpenKeys([]);
  };

  return (
    <Menu
      onOpenChange={onOpenChange}
      onClick={onClick}
      mode={!topMenu || window.innerWidth <= 991 ? "inline" : "horizontal"}
      theme={darkMode && "dark"}
      // // eslint-disable-next-line no-nested-ternary
      defaultSelectedKeys={["home"]}
      activeKey={"home"}
      overflowedIndicator={<FeatherIcon icon="more-vertical" />}
      openKeys={openKeys}
    >
      <Menu.Item
        key="home"
        title={null}
        label={t("item_therapists")}
        icon={
          <img
            alt="hpl"
            src={require(`../static/img/arc/Therapist_list_icon.png`)}
            style={{ float: "left", width: "auto", height: "20px" }}
          />
        }
        onClick={() => nav("/admin")}
      >
        {t("item_therapists")}
      </Menu.Item>
    </Menu>
  );
};

MenuItems.propTypes = {
  darkMode: propTypes.bool,
  topMenu: propTypes.bool,
  toggleCollapsed: propTypes.func,
  events: propTypes.object,
  translation: propTypes.func,
};

export default MenuItems;
