class ApiCallReturnCodes {
  static GENERIC_ERROR = -1;
  static INTERNAL_ERR = 0;
  static DATA_ERR = 1;
  static EXPIRED_TOKEN_ERR = 2;
  static INVALID_TOKEN_ERR = 3;
  static USERNAME_ERR = 4;
  static PWD_REUSED_ERR = 5;
  static MAIL_ERR = 6;
  static NOT_FOUND_ERR = 7;
  static NOT_AUTH_ERR = 8;
  static INVALID_PWD_ERR = 9;
  static NATIONAL_ID_CODE_ERR = 10;
  static NOT_ALLOWED_ERR = 11;
  static UNIQUE_CARE_MANAGER_ERR = 12;
  static INVALID_ARC_UNIT_SN_ERR = 13;
  static ARC_UNIT_ALREADY_EXISTS_ERR = 14;
  static LICENSE_ALREADY_FREE_ERR = 15;
  static LICENSE_ALREADY_ASSOCIATED_ERR = 16;
  static LICENSE_NOT_FOUND_ERR = 17;
  static FCM_RESPONSE_ERR = 18;
  static CORRUPTION_ERR = 19;
  static USER_DATA_ERR = 20;
  static NEED_CONSENT_ERR = 21;
  static NEED_CONSENT_UPD_ERR = 22;
  static INVALID_CONSENT_ERR = 23;
  static CONSENT_ALREADY_REGISTERED_ERR = 24;
  static UNREGISTERED_CONSENT_ERR = 25;
  static ACCOUNT_DELETION_REQUEST_ERR = 26;
  static REQUEST_ALREADY_SENT = 27;
  static READ_TIMEOUT_ERR = 28;
  static REQUEST_EXC_ERR = 29;
  static DELETED_INSTANCE_ERR = 30;
  static NOT_DELETABLE_INSTANCE_ERR = 31;
  static NOT_EDITABLE_INSTANCE_ERR = 32;
  static NOT_PUBLISHED_ERR = 33;
  static CONFLICT_ERR = 34;
  static FORBIDDEN_ERR = 35;
  static INVALID_STATUS_ERR = 36;
  static AUTHORIZATION_FAILURE_ERR = 37;
  static NOT_INTERRUPTABLE_INSTANCE_ERR = 38;
  static NAT_ID_DATA_ERR = 39;
  static NAT_ID_NOT_MATCHING_ERR = 40;

  static GENERIC_WARN = -1;
  static INTERNAL_WARN = 0;
  static MAIL_WARN = 1;
  static UNREGISTERED_CONSENT_WARN = 2;
  static UNREGISTERED_CONSENT_AND_MAIL_WARN = 3;
  static ACCOUNT_DELETION_REQUEST_WARN = 4;

  static SUCCESS = 200;
  static NEW_LOGIN_REQUIRED_ERR = 401;

  static HTTP_OK = 200;
  static HTTP_CREATED = 201;
  static HTTP_OK_NO_CONTENT = 204;
  static HTTP_BAD_REQUEST = 400;
  static HTTP_UNAUTHORIZED_ERR = 401;
  static HTTP_FORBIDDEN_ERR = 403;
}
export { ApiCallReturnCodes };
