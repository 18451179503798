import PropTypes from "prop-types";
import { getDocSrc } from "../../config/api/docs";

const DocFrame = (props) => {
  const { usr_type, lang, doc } = props;
  let src = getDocSrc(usr_type, lang, doc);
  return src ? (
    <iframe
      id="doc-frame"
      title="doc-frame"
      src={src}
      style={{
        height: "70vh",
        width: "45vw",
        borderWidth: "0px",
      }}
    ></iframe>
  ) : (
    <iframe
      title="doc-not-found-frame"
      srcDoc={"Requested document is not available"}
      style={{
        height: "70vh",
        width: "45vw",
        borderWidth: "0px",
      }}
    ></iframe>
  );
};

DocFrame.propTypes = {
  usr_type: PropTypes.string,
  lang: PropTypes.string,
  doc: PropTypes.string,
};
export { DocFrame };
