import React, { lazy, Suspense } from "react";
import { Spin } from "antd";
import { Routes, Route } from "react-router-dom";
import AuthLayout from "../container/profile/authentication/Index";
import AuthenticatedRoute from "../utility/authenticatedRoute";

const Login = lazy(() =>
  import("../container/profile/authentication/overview/SignIn")
);
const AccessDenied = lazy(() =>
  import("../container/profile/authentication/overview/AccessDenied")
);
const NotFound = lazy(() => import("../container/NotFound"));
const ResetPassword = lazy(() =>
  import("../container/profile/authentication/overview/ResetPassword")
);
const FinalizeRegistration = lazy(() =>
  import("../container/profile/authentication/overview/FinalizeRegistration")
);
const ForgotPass = lazy(() =>
  import("../container/profile/authentication/overview/ForgotPassword")
);

const RegisterConsents = lazy(() =>
  import("../container/profile/authentication/overview/RegisterConsents")
);

const FrontendRoutes = () => {
  return (
    <Suspense
      fallback={
        <div className="spin">
          <Spin />
        </div>
      }
    >
      <Routes>
        <Route exact path="/" element={<Login />} />
        <Route exact path="/access_denied" element={<AccessDenied />} />
        <Route exact path="/forgotPassword" element={<ForgotPass />} />
        <Route
          exact
          path="/register_consents/:action"
          element={<AuthenticatedRoute element={<RegisterConsents />} />}
        />
        <Route path="/pwd_reset/*" element={<ResetPassword />} />
        <Route path="/register/*" element={<FinalizeRegistration />} />
        <Route path="/*" element={<NotFound />} />
      </Routes>
    </Suspense>
  );
};

export default AuthLayout(FrontendRoutes);
