import { ApiCallReturnCodes } from "../config/api/ApiCallReturnCodes";

/**
 * Return ellipsis of a given string
 * @param {string} text
 * @param {number} size
 */
const ellipsis = (text, size) => {
  return `${text.split(" ").slice(0, size).join(" ")}...`;
};

/**
 * Return action string for a given consent-status errCode
 * @param {int}
 */
const toConsentAction = (value) => {
  return value === ApiCallReturnCodes.NEED_CONSENT_ERR ? "register" : "update";
};

export { ellipsis, toConsentAction };
