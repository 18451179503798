import Styled from "styled-components";

const Aside = Styled.aside`
  width: auto;
  height: 100vh;
  position: relative;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: left top;
  .bottomShape {
    position: absolute;
    bottom: 0;
    left: 0;
  }
  .auth-side-content{
    @media only screen and (max-width: 991px){
      h1{
        font-size: 20px;
      }
    }
    @media only screen and (max-width: 767px){
      h1{
        font-size: 24px;
        margin-bottom: 28px;
      }
    }
  }
`;

const Content = Styled.div`
    @media only screen and (max-width: 767px){
      text-align: center;
    }
    .auth-content-figure{
      @media only screen and (max-width: 1199px){
        max-width: 420px;
      }
      @media only screen and (max-width: 991px){
        max-width: 100%;
      }
    }
`;

const AuthWrapper = Styled.div`
  height: 100%;
  
  @media only screen and (max-width: 767px){
    text-align: center;
  }
  h1{
    font-size: 22px;
    font-weight: 600;
    margin-top: 32px;
    margin-bottom: 32px;
  }

  button{
    font-family:'Inter';
    font-size: 14px;
    font-weight: 600;
    border-radius: 6px;
    height: 48px;
    margin-top: 48px;
    &.btn-link{
      height: auto !important;
      margin-top: 0px;
      padding: 0px 4px;
    }
    &.btn-link:hover{
      background: transparent !important;
      border-color: transparent !important;
    }
    &.btn-signin{
      min-width: 185px;
    }
    &.btn-create:not([loading]){
      background: ${({ theme }) => theme["arc-cyan-dark"]};
      border-color: ${({ theme }) => theme["arc-cyan-dark"]};
      min-width: 185px;
    }
    &.btn-next{
      background: ${({ theme }) => theme["arc-cyan-dark"]};
      border-color: ${({ theme }) => theme["arc-cyan-dark"]};
      min-width: 185px;
      margin-top: 0px;
    }
    &.btn-create:not([loading]):hover{
      background: ${({ theme }) => theme["arc-cyan-dark-extra"]};
      border-color: ${({ theme }) => theme["arc-cyan-dark-extra"]};
    }
    &.btn-next:hover{
      background: ${({ theme }) => theme["arc-cyan-dark-extra"]};
      border-color: ${({ theme }) => theme["arc-cyan-dark-extra"]};
      min-width: 185px;
    }
    &.btn-next[disabled]{
      background: ${({ theme }) => theme["arc-cyan-dark-mid"]};
      border-color: ${({ theme }) => theme["arc-cyan-dark-mid"]};
    }
    &.btn-next[disabled]:hover{
      background: ${({ theme }) => theme["arc-cyan-dark-light"]} !important;
    }
    &.btn-reset{
      min-width: 185px;
    }
  }
  .ant-alert-info{
    background-color: ${({ theme }) => theme["arc-alert-info-background"]};
    border: 1px solid ${({ theme }) => theme["arc-alert-info-border"]};
    .ant-alert-icon{
      color: ${({ theme }) => theme["arc-alert-info-icon"]};
    }
  }

  .ant-checkbox-checked, ant-checkbox-indeterminate{
    .ant-checkbox-inner{
      background: ${({ theme }) => theme["arc-cyan-dark"]};
      border-color: ${({ theme }) => theme["arc-cyan-dark"]};
    }
  }

  .ant-card{
    .popup-title{
      font-size: 20px;
      font-weight: 500;
      margin-top: 24px;
      .colored{
        color: ${({ theme }) => theme["arc-cyan-dark"]};
        font-weight: 600;
      }
    }
  
    .popup-text{
      margin-top: 24px;
      .colored{
        color: ${({ theme }) => theme["arc-cyan-dark"]};
        font-weight: 500;
      }
    }
    margin-bottom: 0px !important
    .ant-card-body{
      padding: 48px !important;
      .arc-icon{
        .arc-icon-fail{
          color: white;
          border-radius: 24px;
          width: 48px;
          height: 48px;
          background-color:${({ theme }) => theme["arc-error-icon"]};
        }
        .arc-icon-success{
          color: white;
          border-radius: 24px;
          width: 48px;
          height: 48px;
          background-color:${({ theme }) => theme["arc-success-icon"]};
        }
        .arc-icon-warn{
          color: white;
          border-radius: 24px;
          width: 48px;
          height: 48px;
          background-color:${({ theme }) => theme["arc-warn-icon"]};
        }
      }
     }
    }
  }

  .auth-contents{
    display: flex;
    align-items: center;
    justify-content: center;
    form{
      min-width: 270px;
      max-width: 640px;
      @media only screen{
        padding: 24px;
      }
      h1{
        font-size: 24px;
        font-weight: 600;
        margin-bottom: 32px;
        
        input::placeholder{
          color: ${({ theme }) => theme["extra-light-color"]};
        }
      }
    }
      .auth-form-action{
        margin-bottom: 20px;
        display: flex;
        justify-content: space-between;
        @media only screen and(max-width: 379px){
          flex-flow: column;
        }
      }
    }
    .forgot-pass-link{
      color: ${({ theme }) => theme["arc-cyan-dark"]}
    }
    .return-text{
      margin-top: 24px;
    }
    .ant-form-item{
      margin-bottom: 16px;
    }
    .ant-form-item-explain-error{
      margin-top: 2px;
    }
    .ant-col{
      padding: 0 0 4px;
    }
    .form-divider{
    font-size: 13px;
    color: ${({ theme }) => theme["gray-solid"]};
    text-align: center;
    position: relative;
    margin-bottom: 16px;
      &:before{
      content: '';
      position: absolute;
      width: 100 %;
      left: 0;
      top: 50 %;
      transform: translateY(-50 %);
      z-index: 1;
      height: 1px;
      background: ${({ theme }) => theme["border-color-light"]};
    }
      span{
      background: #fff;
      padding: 0 15px;
      display: inline-block;
      position: relative;
      z-index: 2;
    }
      li{
      padding: 6px;
        a{
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 6px;
        height: 48px;
        padding: 0 15px;
        border: 1px solid ${({ theme }) => theme["border-color-light"]};
        background: ${({ theme }) => theme["bg-color-light"]};
        color: ${({ theme }) => theme["text-color"]};
        font-weight: 500;
        @media only screen and(max-width: 379px){
          height: 44px;
          padding: 0 12px;
        }
        span: not(.anticon){
          display: inline-block;
          margin-left: 5px;
        }
        svg,
          i{
          width: 20px;
          height: 20px;
        }
      }
    }
  }
}
`;

export { Aside, Content, AuthWrapper };
