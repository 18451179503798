const getItem = (key, storageType = "local") => {
  if (typeof window == "undefined") {
    return "";
  }

  const val =
    storageType === "local"
      ? localStorage.getItem(key)
      : sessionStorage.getItem(key);
  try {
    return JSON.parse(val);
  } catch (err) {
    return val;
  }
};

const setItem = (key, value, storageType = "local") => {
  const stringify = typeof value !== "string" ? JSON.stringify(value) : value;
  return storageType === "local"
    ? localStorage.setItem(key, stringify)
    : sessionStorage.setItem(key, stringify);
};

const removeItem = (key, storageType = "local") => {
  return storageType === "local"
    ? localStorage.removeItem(key)
    : sessionStorage.removeItem(key);
};

export { getItem, setItem, removeItem };
