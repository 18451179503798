const actions = {
  LOGIN_BEGIN: "LOGIN_BEGIN",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGIN_ERR: "LOGIN_ERR",
  LOGIN_FORBIDDEN: "LOGIN_FORBIDDEN",

  RESET_PWD_BEGIN: "RESET_PWD_BEGIN",
  RESET_PWD_SUCCESS: "RESET_PWD_SUCCESS",
  RESET_PWD_ERR: "RESET_PWD_ERR",

  REQUEST_PWD_RESET_BEGIN: "REQUEST_PWD_RESET_BEGIN",
  REQUEST_PWD_RESET_SUCCESS: "REQUEST_PWD_RESET_SUCCESS",
  REQUEST_PWD_RESET_ERR: "REQUEST_PWD_RESET_ERR",

  FINALIZE_REGISTRATION_BEGIN: "FINALIZE_REGISTRATION_BEGIN",
  FINALIZE_REGISTRATION_SUCCESS: "FINALIZE_REGISTRATION_SUCCESS",
  FINALIZE_REGISTRATION_ERR: "FINALIZE_REGISTRATION_ERR",

  LOGOUT_BEGIN: "LOGOUT_BEGIN",
  LOGOUT_SUCCESS: "LOGOUT_SUCCESS",
  LOGOUT_ERR: "LOGOUT_ERR",

  REGISTER_CONSENTS_BEGIN: "REGISTER_CONSENTS_BEGIN",
  REGISTER_CONSENTS_SUCCESS: "REGISTER_CONSENTS_SUCCESS",
  REGISTER_CONSENTS_ERR: "REGISTER_CONSENTS_ERR",

  PRIV_REQ_CONSENTS_ACTION: "PRIV_REQ_CONSENTS_ACTION",

  _requestConsentsAction: (consentsStatus) => {
    return {
      type: actions.PRIV_REQ_CONSENTS_ACTION,
      consentsStatus: consentsStatus,
    };
  },

  loginBegin: () => {
    return {
      type: actions.LOGIN_BEGIN,
    };
  },

  loginSuccess: (usrType, consentsStatus) => {
    return {
      type: actions.LOGIN_SUCCESS,
      usrType: usrType,
      consentsStatus: consentsStatus,
    };
  },

  loginErr: (err) => {
    return {
      type: actions.LOGIN_ERR,
      err,
    };
  },
  loginForbidden: (usrType) => {
    return {
      type: actions.LOGIN_FORBIDDEN,
      usrType: usrType,
    };
  },

  registerConsentsBegin: () => {
    return {
      type: actions.REGISTER_CONSENTS_BEGIN,
    };
  },

  registerConsentsSuccess: (consentsStatus) => {
    return {
      type: actions.REGISTER_CONSENTS_SUCCESS,
      consentsStatus: consentsStatus,
    };
  },

  registerConsentsErr: (err, consentsStatus) => {
    return {
      type: actions.REGISTER_CONSENTS_ERR,
      consentsStatus: consentsStatus,
      err,
    };
  },

  resetPwdBegin: () => {
    return {
      type: actions.RESET_PWD_BEGIN,
    };
  },

  resetPwdSuccess: (data) => {
    return {
      type: actions.RESET_PWD_SUCCESS,
      data,
    };
  },

  resetPwdErr: (err) => {
    return {
      type: actions.RESET_PWD_ERR,
      err,
    };
  },

  requestPwdResetBegin: () => {
    return {
      type: actions.REQUEST_PWD_RESET_BEGIN,
    };
  },

  requestPwdResetSuccess: (data) => {
    return {
      type: actions.REQUEST_PWD_RESET_SUCCESS,
      data,
    };
  },

  requestPwdResetErr: (err) => {
    return {
      type: actions.REQUEST_PWD_RESET_ERR,
      err,
    };
  },

  finalizeRegistrationBegin: () => {
    return {
      type: actions.FINALIZE_REGISTRATION_BEGIN,
    };
  },

  finalizeRegistrationSuccess: (data) => {
    return {
      type: actions.FINALIZE_REGISTRATION_SUCCESS,
      data,
    };
  },

  finalizeRegistrationErr: (err) => {
    return {
      type: actions.FINALIZE_REGISTRATION_ERR,
      err,
    };
  },

  logoutBegin: () => {
    return {
      type: actions.LOGOUT_BEGIN,
    };
  },

  logoutSuccess: () => {
    return {
      type: actions.LOGOUT_SUCCESS,
    };
  },

  logoutErr: (err) => {
    return {
      type: actions.LOGOUT_ERR,
      err,
    };
  },
};

export default actions;
