import actions from "./actions";
import { API } from "../../config/api";
import {
  DataService,
  cleanupStorage,
} from "../../config/dataService/dataService";
import { setItem, getItem } from "../../utility/localStorageControl";
import { ApiCallReturnCodes } from "../../config/api/ApiCallReturnCodes";
import { cleanUp } from "../users/actionCreator";

const {
  HTTP_OK,
  HTTP_OK_NO_CONTENT,
  HTTP_FORBIDDEN_ERR,
  SUCCESS,
  GENERIC_ERROR,
  CONSENT_ALREADY_REGISTERED_ERR,
} = ApiCallReturnCodes;
const {
  loginBegin,
  loginSuccess,
  loginForbidden,
  loginErr,
  resetPwdBegin,
  resetPwdSuccess,
  resetPwdErr,
  requestPwdResetBegin,
  requestPwdResetSuccess,
  requestPwdResetErr,
  finalizeRegistrationBegin,
  finalizeRegistrationSuccess,
  finalizeRegistrationErr,
  registerConsentsBegin,
  registerConsentsSuccess,
  registerConsentsErr,
  logoutBegin,
  logoutSuccess,
  logoutErr,
} = actions;

const ARC_CUSTOMER_ADMIN = 3;

const cleanStorage = (storageType = getItem("storageType")) => {
  cleanupStorage(storageType);
};

const login = (username, password, keep_logged) => {
  return async (dispatch) => {
    const storageType = keep_logged ? "local" : "session";
    setItem("storageType", storageType);
    try {
      await dispatch(loginBegin());
      const form_data = new FormData();
      form_data.append("username", username);
      form_data.append("password", password);
      const response = await DataService.getAccessToken(form_data);

      if (response.status !== HTTP_OK) {
        console.error("Login status code != HTTP_OK");
        cleanStorage(storageType);
        await dispatch(loginErr(true));
        return;
      }

      setItem("session_info", response.data, storageType);

      const myTypeRes = await DataService.get(API.auth.myType);
      if (myTypeRes.status !== HTTP_OK) {
        console.error("Type check status code != HTTP_OK");
        cleanStorage(storageType);
        await dispatch(loginErr(true));
        return;
      }
      const myType = myTypeRes.data["userType"];
      if (myType !== ARC_CUSTOMER_ADMIN) {
        console.log("Blocking forbidden user login");
        cleanStorage(storageType);
        dispatch(loginForbidden(myType));
        return;
      }

      const consentsCheckRes = await DataService.get(API.consents.status);
      if (
        consentsCheckRes.status !== HTTP_OK_NO_CONTENT &&
        consentsCheckRes.status !== HTTP_FORBIDDEN_ERR
      ) {
        console.error(
          "Consents check status code != HTTP_OK_NO_CONTENT or HTTP_FORBIDDEN_ERR"
        );
        cleanStorage(storageType);
        await dispatch(loginErr(true));
        return;
      }

      let consentsStatus =
        consentsCheckRes.status === HTTP_OK_NO_CONTENT
          ? SUCCESS
          : consentsCheckRes.data["errCode"];
      setItem("consentsStatus", consentsStatus, storageType);
      dispatch(loginSuccess(myType, consentsStatus));
    } catch (err) {
      console.error(err);
      cleanStorage(storageType);
      await dispatch(loginErr(true));
    }
  };
};

const logOut = () => {
  return async (dispatch) => {
    try {
      await dispatch(logoutBegin());
      await dispatch(cleanUp);
      cleanStorage();
      await dispatch(logoutSuccess());
    } catch (err) {
      console.log("logout err", err);
      dispatch(logoutErr(err));
    }
  };
};

const resetPassword = (token, username, password) => {
  return async (dispatch) => {
    try {
      await dispatch(resetPwdBegin());
      const payload = {
        token: token,
        username: username,
        newPassword: password,
      };
      const response = await DataService.post(API.auth.resetPwd, payload);

      if (response.status === HTTP_OK) {
        await dispatch(resetPwdSuccess(response.data));
      } else {
        await dispatch(resetPwdErr(response.data));
        console.error("Err ", response.status, " : ", response.data);
      }
    } catch (err) {
      await dispatch(
        resetPwdErr({
          code: GENERIC_ERROR,
          errMsg: err.response,
        })
      );
      console.error("exception: ", err.response);
    }
  };
};

const finalizeRegistration = (token, data) => {
  return async (dispatch) => {
    try {
      await dispatch(finalizeRegistrationBegin());
      const payload = {
        token: token,
        username: data.username,
        newPassword: data.pwd,
        consentData: {
          termsAndConditions: data.termsAndConditions,
          privacyPolicy: data.privacyPolicy,
          purposes: {},
        },
      };
      const response = await DataService.post(
        API.auth.finalizeRegistration,
        payload
      );

      if (response.status === HTTP_OK) {
        await dispatch(finalizeRegistrationSuccess(response.data));
        console.log("OK 200: ", response.data);
      } else {
        await dispatch(finalizeRegistrationErr(response.data));
        console.error("Err ", response.status, " : ", response.data);
      }
    } catch (err) {
      await dispatch(
        finalizeRegistrationErr({
          code: GENERIC_ERROR,
          errMsg: err.response,
        })
      );
      console.error("exception: ", err.response);
    }
  };
};

const registerConsents = (values) => {
  return async (dispatch) => {
    try {
      await dispatch(registerConsentsBegin());
      const payload = {
        consentData: {
          termsAndConditions: values.termsAndConditions,
          privacyPolicy: values.privacyPolicy,
          purposes: {},
        },
      };
      const res = await DataService.post(API.consents.register, payload);
      const { status, data } = res;
      if (
        status === HTTP_OK_NO_CONTENT ||
        (status === HTTP_FORBIDDEN_ERR &&
          data.errCode &&
          data.errCode === CONSENT_ALREADY_REGISTERED_ERR)
      ) {
        setItem("consentsStatus", SUCCESS, getItem("storageType"));
        await dispatch(registerConsentsSuccess(SUCCESS));
        return;
      } else {
        setItem("consentsStatus", data.errCode, getItem("storageType"));
        await dispatch(registerConsentsErr(data));
        return;
      }
    } catch (err) {
      console.error("exception: ", err);
      await dispatch(
        registerConsentsErr({
          code: GENERIC_ERROR,
          errMsg: err.response,
        })
      );
    }
  };
};

const forgotPassword = (payload) => {
  return async (dispatch) => {
    try {
      await dispatch(requestPwdResetBegin());
      const response = await DataService.post(
        API.auth.requestPwdReset,
        payload
      );

      if (response.status === HTTP_OK) {
        await dispatch(requestPwdResetSuccess(response.data));
        console.log("OK 200: ", response.data);
      } else {
        await dispatch(requestPwdResetErr(response.data));
        console.error("Err ", response.status, " : ", response.data);
      }
    } catch (err) {
      await dispatch(
        requestPwdResetErr({
          code: GENERIC_ERROR,
          errMsg: err.response,
        })
      );
      console.error("exception: ", err.response);
    }
  };
};

export {
  login,
  logOut,
  resetPassword,
  finalizeRegistration,
  forgotPassword,
  registerConsents,
  ARC_CUSTOMER_ADMIN,
};
