import React, { useState } from "react";
import { Avatar, Button, Col, Row } from "antd";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import FeatherIcon from "feather-icons-react";
import { InfoWraper, NavAuth, UserDropDwon } from "./auth-info-style";
import { Popover } from "../../popup/popup";
import { Dropdown } from "../../dropdown/dropdown";
import { logOut } from "../../../redux/authentication/actionCreator";
import { useTranslation } from "react-i18next";

const AuthInfo = () => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation(["top_right_info_menu"]);
  const [flag, setFlag] = useState(i18n.language.includes("it") ? "it" : "en");

  const SignOut = (e) => {
    dispatch(logOut());
  };

  const userContent = (
    <UserDropDwon>
      <Link className="user-dropdwon__bottomAction" onClick={SignOut} to="/">
        <FeatherIcon icon="log-out" style={{ verticalAlign: "middle" }} />
        <span>{t("btn_logout")}</span>
      </Link>
    </UserDropDwon>
  );

  const onChangeLanguage = (value) => {
    i18n.changeLanguage(value);
    setFlag(value);
  };

  const country = (
    <NavAuth>
      <p style={{ marginBottom: "8px", width: "-webkit-fill-available" }}>
        <Button
          type="link"
          onClick={() => onChangeLanguage("en")}
          icon={
            <img
              alt="en"
              src={require("../../../static/img/flag/en.png")}
              width={"32px"}
            />
          }
          style={{ width: "inherit", textAlign: "inherit" }}
        >
          {t("btn_lang_en")}
        </Button>
      </p>
      <p style={{ marginBottom: "0px", width: "-webkit-fill-available" }}>
        <Button
          type="link"
          onClick={() => onChangeLanguage("it")}
          icon={
            <img
              alt="it"
              src={require("../../../static/img/flag/it.png")}
              width={"32px"}
            />
          }
          style={{ width: "inherit", textAlign: "inherit" }}
        >
          {t("btn_lang_it")}
        </Button>
      </p>
    </NavAuth>
  );

  return (
    <InfoWraper>
      <Row gutter={32} style={{ alignItems: "inherit" }}>
        <Col>
          <div className="flag-select">
            <Dropdown
              placement="bottomRight"
              content={country}
              action={["hover"]}
            >
              <img
                src={require(`../../../static/img/flag/${flag}.png`)}
                alt="lang"
                width={"28px"}
              />
            </Dropdown>
          </div>
        </Col>
        <Col>
          <div className="nav-author">
            <Popover
              placement="bottomRight"
              content={userContent}
              action="click"
            >
              <Avatar src={require(`../../../static/img/arc/Admin_Icon.png`)} />
            </Popover>
          </div>
        </Col>
      </Row>
      {/* <Settings /> */}
      {/* <Support /> */}
    </InfoWraper>
  );
};

export default AuthInfo;
