import React, { lazy, Suspense } from "react";
import { Spin } from "antd";
import { Route, Routes } from "react-router-dom";
import withAdminLayout from "../../layout/withAdminLayout";
import ProtectedRoute from "../../utility/protectedRoute";

const NotFound = lazy(() => import("../../container/NotFound"));

const TherapistTable = lazy(() =>
  import("../../container/pages/UserListDataTable")
);
const AddNewUser = lazy(() => import("../../container/pages/AddNewUser"));
const InfoUser = lazy(() => import("../../container/pages/InfoUser"));
const EditUser = lazy(() => import("../../container/pages/EditUser"));

const Admin = () => {
  return (
    <Suspense
      fallback={
        <div className="spin">
          <Spin />
        </div>
      }
    >
      <Routes>
        <Route
          exact
          path=""
          element={<ProtectedRoute element={<TherapistTable />} />}
        />
        <Route
          exact
          path="register-hp"
          element={<ProtectedRoute element={<AddNewUser />} />}
        />
        <Route
          exact
          path="info-hp"
          element={<ProtectedRoute element={<InfoUser />} />}
        />
        <Route
          exact
          path="edit-hp"
          element={<ProtectedRoute element={<EditUser />} />}
        />
        <Route path="/*" element={<NotFound />} />
      </Routes>
    </Suspense>
  );
};

export default withAdminLayout(Admin);
