import { getItem } from "../../utility/localStorageControl";
import actions from "./actions";

const {
  LOGIN_BEGIN,
  LOGIN_SUCCESS,
  LOGIN_ERR,
  LOGIN_FORBIDDEN,
  LOGOUT_BEGIN,
  LOGOUT_SUCCESS,
  LOGOUT_ERR,
  RESET_PWD_BEGIN,
  RESET_PWD_SUCCESS,
  RESET_PWD_ERR,
  REQUEST_PWD_RESET_BEGIN,
  REQUEST_PWD_RESET_SUCCESS,
  REQUEST_PWD_RESET_ERR,
  FINALIZE_REGISTRATION_BEGIN,
  FINALIZE_REGISTRATION_SUCCESS,
  FINALIZE_REGISTRATION_ERR,
  REGISTER_CONSENTS_BEGIN,
  REGISTER_CONSENTS_SUCCESS,
  REGISTER_CONSENTS_ERR,
  PRIV_REQ_CONSENTS_ACTION,
} = actions;

const initState = {
  login: getItem("session_info", getItem("storageType")) != null,
  consentsStatus: getItem("consentsStatus", getItem("storageType")),
  loading: false,
  forbidden: false,
  error: null,
  response: null,
  usrType: -1,
};

/**
 *
 * @todo impure state mutation/explaination
 */
const AuthReducer = (state = initState, action) => {
  const { type, data, usrType, consentsStatus, err } = action;
  switch (type) {
    case LOGIN_BEGIN:
      return {
        ...initState,
        login: null,
        loading: true,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        login: true,
        usrType: usrType,
        consentsStatus: consentsStatus,
        loading: false,
      };
    case LOGIN_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case LOGIN_FORBIDDEN:
      return {
        ...state,
        usrType: usrType,
        forbidden: true,
        loading: false,
      };
    case LOGOUT_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case LOGOUT_SUCCESS:
      return {
        ...initState,
      };
    case LOGOUT_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case RESET_PWD_BEGIN:
    case REQUEST_PWD_RESET_BEGIN:
    case FINALIZE_REGISTRATION_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
        response: null,
      };
    case RESET_PWD_SUCCESS:
    case REQUEST_PWD_RESET_SUCCESS:
    case FINALIZE_REGISTRATION_SUCCESS:
      return {
        ...state,
        response: data,
        loading: false,
      };
    case RESET_PWD_ERR:
    case REQUEST_PWD_RESET_ERR:
    case FINALIZE_REGISTRATION_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case REGISTER_CONSENTS_BEGIN:
      return {
        ...state,
        consentsStatus: null,
        error: null,
        loading: true,
      };
    case REGISTER_CONSENTS_SUCCESS:
      return {
        ...state,
        consentsStatus: consentsStatus,
        loading: false,
      };
    case REGISTER_CONSENTS_ERR:
      return {
        ...state,
        error: err,
        consentsStatus: consentsStatus || state.consentsStatus,
        loading: false,
      };
    case PRIV_REQ_CONSENTS_ACTION:
      return {
        ...state,
        consentsStatus: state.login ? consentsStatus : null,
      };
    default:
      return state;
  }
};
export default AuthReducer;
