import actions from "./actions";
import { API } from "../../config/api";

import { DataService } from "../../config/dataService/dataService";
import { ApiCallReturnCodes } from "../../config/api/ApiCallReturnCodes";

const {
  getAffiliationsBegin,
  getAffiliationsSuccess,
  getAffiliationsError,
  getTherapistsBegin,
  getTherapistsSuccess,
  getTherapistsError,
  addTherapistBegin,
  addTherapistSuccess,
  addTherapistError,
  updTherapistBegin,
  updTherapistSuccess,
  updTherapistError,
  _cleanup,
} = actions;

const cleanUp = () => {
  return async (dispatch) => {
    await dispatch(_cleanup);
  };
};

const getAffiliations = () => {
  return async (dispatch) => {
    try {
      await dispatch(getAffiliationsBegin());
      const response = await DataService.get(API.c_admin.affiliations);
      if (response.status === ApiCallReturnCodes.HTTP_OK) {
        await dispatch(getAffiliationsSuccess(response.data));
      } else {
        await dispatch(getAffiliationsError(response.data));
      }
    } catch (err) {
      // Enter here if catching a Promise reject -> for time being, an error in token refresh
      await dispatch(
        getAffiliationsError({
          errCode: ApiCallReturnCodes.NEW_LOGIN_REQUIRED_ERR,
          msg: err.response,
        })
      );
    }
  };
};

const getTherapists = () => {
  return async (dispatch) => {
    try {
      await dispatch(getTherapistsBegin());

      const response = await DataService.get(API.c_admin.therapists);
      if (response.status === ApiCallReturnCodes.HTTP_OK) {
        await dispatch(getTherapistsSuccess(response.data));
      } else {
        await dispatch(getTherapistsError(response.data));
      }
    } catch (err) {
      // Enter here if catching a Promise reject -> for time being, an error in token refresh
      await dispatch(
        getTherapistsError({
          errCode: ApiCallReturnCodes.NEW_LOGIN_REQUIRED_ERR,
          msg: err.response,
        })
      );
    }
  };
};

const addTherapist = (user_data) => {
  return async (dispatch) => {
    try {
      await dispatch(addTherapistBegin());

      const response = await DataService.post(
        API.c_admin.therapists,
        user_data
      );

      if (response.status === ApiCallReturnCodes.HTTP_CREATED) {
        await dispatch(addTherapistSuccess(response.data));
      } else {
        // Backend handled 400 500 errors -> handled through internal error codes
        await dispatch(addTherapistError(response.data));
        console.error("Err ", response.status, " - ", response.data);
      }
    } catch (err) {
      // Enters here upon token refresh failure
      await dispatch(
        addTherapistError({
          errCode: ApiCallReturnCodes.NEW_LOGIN_REQUIRED_ERR,
          msg: err.response,
        })
      );
      console.error("exception: ", err.response);
    }
  };
};

const updateTherapist = (patch_data, id) => {
  return async (dispatch) => {
    try {
      await dispatch(updTherapistBegin(id));
      const response = await DataService.patch(
        API.c_admin.therapists + id + "/",
        patch_data
      );
      if (response.status === ApiCallReturnCodes.HTTP_OK) {
        await dispatch(updTherapistSuccess(response.data));
      } else {
        // Backend handled 400 500 errors -> handled through internal error codes
        await dispatch(updTherapistError(response.data));
        console.error("Err ", response.status, " - ", response.data);
      }
    } catch (err) {
      // Enters here upon token refresh failure
      await dispatch(
        updTherapistError({
          errCode: ApiCallReturnCodes.NEW_LOGIN_REQUIRED_ERR,
          msg: err.response,
        })
      );
      console.error("exception: ", err.response);
    }
  };
};

export {
  getAffiliations,
  getTherapists,
  addTherapist,
  updateTherapist,
  cleanUp,
};
