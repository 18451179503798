const API_ENDPOINT = window.__RUNTIME_CONFIG__.REACT_APP_API_ENDPOINT;
const DOCS_ENDPOINT = API_ENDPOINT + "/internal_docs/latest";

const DOCS = {
  staff: {
    en: {
      tc: "en/staff_terms_and_conditions.html",
      pp: "en/staff_privacy_policy.html",
    },
    it: {
      tc: "it/staff_terms_and_conditions.html",
      pp: "it/staff_privacy_policy.html",
    },
  },
  patients: {
    en: {
      tc: "en/patients_terms_and_conditions.html",
      pp: "en/patients_privacy_policy.html",
    },
    it: {
      tc: "it/patients_terms_and_conditions.html",
      pp: "it/patients_privacy_policy.html",
    },
  },
};

const getDocSrc = (usr_type, lang, doc) => {
  usr_type = Object.keys(DOCS).includes(usr_type) ? usr_type : null;
  if (
    Object.keys(DOCS).includes(usr_type) &&
    Object.keys(DOCS[usr_type]).includes(lang) &&
    Object.keys(DOCS[usr_type][lang]).includes(doc)
  ) {
    return DOCS_ENDPOINT + "/" + DOCS[usr_type][lang][doc];
  }
  return null;
};
export { getDocSrc };
