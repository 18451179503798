import actions from "./actions";

const initialState = {
  t_list: [],
  loading: false,
  posting: false,
  error: null,
  new_user: null,
  post_err: null,
  upd_id: null,
  upd_user: null,
  upd_err: null,
  upd_loading: false,
  affiliations: [],
  aff_error: true,
  aff_loading: false,
};

const initialStateGroup = [];

const {
  GET_AFFILIATIONS_BEGIN,
  GET_AFFILIATIONS_SUCCESS,
  GET_AFFILIATIONS_ERR,
  GET_THERAPISTS_BEGIN,
  GET_THERAPISTS_SUCCESS,
  GET_THERAPISTS_ERR,
  ADD_THERAPIST_BEGIN,
  ADD_THERAPIST_SUCCESS,
  ADD_THERAPIST_ERR,
  UPD_THERAPIST_BEGIN,
  UPD_THERAPIST_SUCCESS,
  UPD_THERAPIST_ERR,
  _CLEANUP,
} = actions;

const userReducer = (state = initialState, action) => {
  const { type, data, err, id } = action;
  switch (type) {
    case GET_AFFILIATIONS_BEGIN:
      return {
        ...state,
        affiliations: [],
        aff_error: false,
        aff_loading: true,
      };
    case GET_AFFILIATIONS_SUCCESS:
      return {
        ...state,
        affiliations: data,
        aff_loading: false,
      };
    case GET_AFFILIATIONS_ERR:
      return {
        ...state,
        aff_error: true,
        aff_loading: false,
      };
    case GET_THERAPISTS_BEGIN:
      return {
        ...initialState,
        error: false,
        loading: true,
      };
    case GET_THERAPISTS_SUCCESS:
      return {
        ...state,
        t_list: data,
        loading: false,
      };
    case GET_THERAPISTS_ERR:
      return {
        ...state,
        loading: false,
        error: err,
      };
    case ADD_THERAPIST_BEGIN:
      return {
        ...state,
        posting: true,
        new_user: null,
        post_err: null,
      };
    case ADD_THERAPIST_SUCCESS:
      return {
        ...state,
        posting: false,
        new_user: data,
      };
    case ADD_THERAPIST_ERR:
      return {
        ...state,
        posting: false,
        post_err: err,
      };
    case UPD_THERAPIST_BEGIN:
      return {
        ...state,
        upd_id: id,
        upd_loading: true,
        upd_user: null,
        upd_err: null,
      };
    case UPD_THERAPIST_SUCCESS:
      return {
        ...state,
        upd_loading: false,
        upd_user: data,
      };
    case UPD_THERAPIST_ERR:
      return {
        ...state,
        upd_loading: false,
        upd_err: err,
      };
    case _CLEANUP:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

const userGroupReducer = (state = initialStateGroup) => {
  return state;
};

export { userReducer, userGroupReducer };
