import React, { lazy, Suspense } from "react";
import { Spin } from "antd";
import { Routes, Route } from "react-router-dom";
import AuthLayout from "../container/profile/authentication/Index";

const TermsAndPrivacy = lazy(() => import("../container/TermsAndPrivacy"));
const NotFound = lazy(() => import("../container/NotFound"));

const DocsRoutes = () => {
  return (
    <Suspense
      fallback={
        <div className="spin">
          <Spin />
        </div>
      }
    >
      <Routes>
        <Route path="/:doc/:for/:lang" element={<TermsAndPrivacy />} />
        <Route path="/*" element={<NotFound />} />
      </Routes>
    </Suspense>
  );
};

export default AuthLayout(DocsRoutes);
