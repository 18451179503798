import React from "react";
import propTypes from "prop-types";
import { useSelector } from "react-redux";

const AuthenticatedRoute = ({ element }) => {
  const isAuthenticated = useSelector((state) => state.auth.login);
  return isAuthenticated ? element : <>{window.location.replace("/")}</>;
};

AuthenticatedRoute.propTypes = {
  element: propTypes.object.isRequired,
};

export default AuthenticatedRoute;
