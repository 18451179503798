const actions = {
  GET_AFFILIATIONS_BEGIN: "GET_AFFILIATIONS_BEGIN",
  GET_AFFILIATIONS_SUCCESS: "GET_AFFILIATIONS_SUCCESS",
  GET_AFFILIATIONS_ERR: "GET_AFFILIATIONS_ERR",
  GET_THERAPISTS_BEGIN: "GET_THERAPISTS_BEGIN",
  GET_THERAPISTS_SUCCESS: "GET_THERAPISTS_SUCCESS",
  GET_THERAPISTS_ERR: "GET_THERAPISTS_ERR",
  ADD_THERAPIST_BEGIN: "ADD_THERAPIST_BEGIN",
  ADD_THERAPIST_SUCCESS: "ADD_THERAPIST_SUCCESS",
  ADD_THERAPIST_ERR: "ADD_THERAPIST_ERR",
  UPD_THERAPIST_BEGIN: "UPD_THERAPIST_BEGIN",
  UPD_THERAPIST_SUCCESS: "UPD_THERAPIST_SUCCESS",
  UPD_THERAPIST_ERR: "UPD_THERAPIST_ERR",
  _CLEANUP: "_CLEANUP",
  getAffiliationsBegin: () => {
    return {
      type: actions.GET_AFFILIATIONS_BEGIN,
    };
  },
  getAffiliationsSuccess: (data) => {
    return {
      type: actions.GET_AFFILIATIONS_SUCCESS,
      data,
    };
  },
  getAffiliationsError: (err) => {
    return {
      type: actions.GET_AFFILIATIONS_ERR,
      err,
    };
  },
  getTherapistsBegin: () => {
    return {
      type: actions.GET_THERAPISTS_BEGIN,
    };
  },
  getTherapistsSuccess: (data) => {
    return {
      type: actions.GET_THERAPISTS_SUCCESS,
      data,
    };
  },
  getTherapistsError: (err) => {
    return {
      type: actions.GET_THERAPISTS_ERR,
      err,
    };
  },
  addTherapistBegin: () => {
    return {
      type: actions.ADD_THERAPIST_BEGIN,
    };
  },
  addTherapistSuccess: (data) => {
    return {
      type: actions.ADD_THERAPIST_SUCCESS,
      data,
    };
  },
  addTherapistError: (err) => {
    return {
      type: actions.ADD_THERAPIST_ERR,
      err,
    };
  },
  updTherapistBegin: (id) => {
    return {
      type: actions.UPD_THERAPIST_BEGIN,
      id,
    };
  },
  updTherapistSuccess: (data) => {
    return {
      type: actions.UPD_THERAPIST_SUCCESS,
      data,
    };
  },
  updTherapistError: (err) => {
    return {
      type: actions.UPD_THERAPIST_ERR,
      err,
    };
  },
  _cleanup: () => {
    return {
      type: actions._CLEANUP,
    };
  },
};

export default actions;
