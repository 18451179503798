import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useDispatch, useStore } from "react-redux";

import { ApiCallReturnCodes } from "../api/ApiCallReturnCodes";
import {
  getItem,
  removeItem,
  setItem,
} from "../../utility/localStorageControl";

import actions from "../../redux/authentication/actions";
import { toConsentAction } from "../../utility/utility";
import { API } from "../../config/api";
import { logOut } from "../../redux/authentication/actionCreator";

const { _requestConsentsAction } = actions;
const API_ENDPOINT = window.__RUNTIME_CONFIG__.REACT_APP_API_ENDPOINT;
const CLIENT_ID = window.__RUNTIME_CONFIG__.REACT_APP_AUTH0_CLIENT_ID;

const authHeader = () => {
  return {
    Authorization: `Bearer ${
      getItem("session_info", getItem("storageType"))?.access_token
    }`,
  };
};

const tokenHeader = () => ({
  "Content-Type": "multipart/form-data",
});

const client = axios.create({
  baseURL: API_ENDPOINT,
  headers: {
    "Content-Type": "application/json",
  },
});

const refreshAccessToken = (formData) => {
  formData.append("grant_type", "refresh_token");
  formData.append("client_id", CLIENT_ID);
  return client({
    method: "POST",
    url: API.auth.token,
    headers: { ...tokenHeader() },
    data: formData,
  });
};

class DataService {
  static getAccessToken(formData) {
    formData.append("grant_type", "password");
    formData.append("client_id", CLIENT_ID);
    return client({
      method: "POST",
      url: API.auth.token,
      headers: { ...tokenHeader() },
      data: formData,
    });
  }

  static get(path = "") {
    return client({
      method: "GET",
      url: path,
      headers: { ...authHeader() },
    });
  }

  static post(path = "", data = {}) {
    return client({
      method: "POST",
      url: path,
      data,
      headers: { ...authHeader() },
    });
  }

  static patch(path = "", data = {}) {
    return client({
      method: "PATCH",
      url: path,
      data: JSON.stringify(data),
      headers: { ...authHeader() },
    });
  }

  static delete(path = "", data = {}) {
    return client({
      method: "DELETE",
      url: path,
      data: JSON.stringify(data),
      headers: { ...authHeader() },
    });
  }

  static put(path = "", data = {}) {
    return client({
      method: "PUT",
      url: path,
      data: JSON.stringify(data),
      headers: { ...authHeader() },
    });
  }
}

const cleanupStorage = (storageType = getItem("storageType")) => {
  removeItem("rbk_user_data", "local");
  removeItem("session_info", storageType);
  removeItem("consentsStatus", storageType);
  removeItem("elapsed", storageType);
  removeItem("last_api_call", storageType);
  removeItem("storageType");
};

const AxiosInterceptor = ({ children }) => {
  const navigate = useNavigate();
  const store = useStore();
  const dispatch = useDispatch();

  useEffect(() => {
    const reqInterceptor = (request) => {
      const now = Date.now();
      const storageType = getItem("storageType");
      const lastApiCall = getItem("last_api_call", storageType);
      const delta = lastApiCall ? (now - lastApiCall) / 1000 : 0;
      setItem("elapsed", delta, storageType);
      setItem("last_api_call", now, storageType);
      return request;
    };

    const resInterceptor = (response) => {
      return response;
    };

    const errInterceptor = (error) => {
      let res = error.response;
      const storageType = getItem("storageType");
      if (res.status === ApiCallReturnCodes.HTTP_UNAUTHORIZED_ERR) {
        if (getItem("elapsed", storageType) > 1800) {
          dispatch(logOut());
          return Promise.reject(error);
        } else {
          // Eject responseInterceptors to prevent infinite looping on token refresh
          client.interceptors.response.eject(resInterceptorManager);
          const formData = new FormData();
          formData.append(
            "refresh_token",
            getItem("session_info", storageType)?.refresh_token
          );
          return refreshAccessToken(formData)
            .then((response) => {
              setItem("session_info", response.data, storageType);
              res.config.headers["Authorization"] = authHeader().Authorization;
              // Retry the initial call, but with the updated token in the headers.
              // Resolves the promise if successful
              return client(res.config);
            })
            .catch((refreshErr) => {
              // Retry failed, clean up and reject the promise
              dispatch(logOut());
              return Promise.reject(refreshErr);
            })
            .finally(client.interceptors.response.use(resInterceptorManager));
        }
      }
      if (
        res.status === ApiCallReturnCodes.HTTP_FORBIDDEN_ERR &&
        res.data.errCode &&
        (res.data.errCode === ApiCallReturnCodes.NEED_CONSENT_ERR ||
          res.data.errCode === ApiCallReturnCodes.NEED_CONSENT_UPD_ERR)
      ) {
        // LT - Note: The only way to modify the redux store is dispatching an action
        store.dispatch(_requestConsentsAction(res.data.errCode));
        navigate("/register_consents/" + toConsentAction(res.data.errCode));
      }
      return res;
    };

    const resInterceptorManager = client.interceptors.response.use(
      resInterceptor,
      errInterceptor
    );

    const reqInterceptorManager = client.interceptors.request.use(
      reqInterceptor
    );
    return () => {
      client.interceptors.request.eject(reqInterceptorManager);
      client.interceptors.response.eject(resInterceptorManager);
    };
  }, [navigate, store]);

  return children;
};

export { DataService, API_ENDPOINT, AxiosInterceptor, cleanupStorage };
