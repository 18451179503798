import React from "react";
import propTypes from "prop-types";
import AuthenticatedRoute from "./authenticatedRoute";
import AuthorizedRoute from "./authorizedRoute";

const ProtectedRoute = ({ element }) => {
  return <AuthenticatedRoute element={<AuthorizedRoute element={element} />} />;
};

ProtectedRoute.propTypes = {
  element: propTypes.object.isRequired,
};

export default ProtectedRoute;
