import { combineReducers } from 'redux';
import authReducer from './authentication/reducers';
import ChangeLayoutMode from './themeLayout/reducers';
import { headerSearchReducer } from './headerSearch/reducers';
import { userReducer, userGroupReducer } from './users/reducers';

const rootReducers = combineReducers({
  headerSearchData: headerSearchReducer,
  ChangeLayoutMode,
  auth: authReducer,
  users: userReducer,
  userGroup: userGroupReducer,
});

export default rootReducers;
