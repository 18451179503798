import React from 'react';
import { Row, Col } from 'antd';

import { Aside, Content } from './overview/style';

const AuthLayout = WraperContent => {
  return () => {
    return (
      <Row>
        <Col xxl={8} xl={9} lg={12} md={8} xs={24}>
          <Aside>
            <div className="auth-side-content">
              <Content>
                <img
                  className="auth-content-figure"
                  src={require('../../../static/img/arc/ImagePortalFinalRoundCyanDark.png')}
                  alt=""
                  style={{ width: '100%', height: '100vh' }}
                />
              </Content>
            </div>
          </Aside>
        </Col>

        <Col xxl={16} xl={15} lg={12} md={16} xs={24}>
          <WraperContent />
        </Col>
      </Row>
    );
  };
};

export default AuthLayout;
