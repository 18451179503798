/**
 * It's suggested to configure the RESTful endpoints in this file
 * so that there is only one source of truth, future update of endpoints
 * could be done from here without refactoring on multiple places throughout the app
 */
const API = {
  auth: {
    token: "/oauth2/token/",
    myType: "/api/my_type/",
    finalizeRegistration: "/api/finalize_registration/",
    resetPwd: "/api/reset_pwd/",
    requestPwdReset: "/api/request_pwd_reset/",
  },
  consents: {
    status: "/api/consents/status/",
    register: "/api/consents/register_consent/",
  },
  c_admin: {
    therapists: "/api/therapists/",
    affiliations: "/api/affiliations/",
  },
};

export { API };
