import React, { Suspense, useEffect, useState } from "react";

import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";

import Admin from "./routes/admin";
import Auth from "./routes/auth";
import Docs from "./routes/docs";

import { Provider, useSelector } from "react-redux";
import { ConfigProvider } from "antd";
import store from "./redux/store";

import "antd/dist/antd.css";
import "./static/css/style.css";
import { ThemeProvider } from "styled-components";

import config from "./config/config";
import { ApiCallReturnCodes } from "./config/api/ApiCallReturnCodes";
import { AxiosInterceptor } from "./config/dataService/dataService";
import ProtectedRoute from "./utility/protectedRoute";

const { theme } = config;

const ProviderConfig = () => {
  const { rtl, isLoggedIn, consentsOk, topMenu, darkMode } = useSelector(
    (state) => {
      return {
        darkMode: state.ChangeLayoutMode.data,
        rtl: state.ChangeLayoutMode.rtlData,
        topMenu: state.ChangeLayoutMode.topMenu,
        isLoggedIn: state.auth.login,
        consentsOk:
          state.auth.consentsStatus &&
          state.auth.consentsStatus === ApiCallReturnCodes.SUCCESS,
      };
    }
  );

  const [path, setPath] = useState(window.location.pathname);

  useEffect(() => {
    let unmounted = false;
    if (!unmounted) {
      setPath(window.location.pathname);
    }
    // eslint-disable-next-line no-return-assign
    return () => (unmounted = true);
  }, [setPath]);

  return (
    <ConfigProvider direction={rtl ? "rtl" : "ltr"}>
      <ThemeProvider theme={{ ...theme, rtl, topMenu, darkMode }}>
        <Router basename={window.__RUNTIME_CONFIG__.PUBLIC_URL}>
          <AxiosInterceptor>
            <Routes>
              <Route exact path="docs/*" element={<Docs />} />
              <Route
                path="/*"
                element={
                  isLoggedIn && consentsOk ? (
                    <Navigate to="/admin" replace />
                  ) : (
                    <Auth />
                  )
                }
              />
              <Route
                exact
                path="/admin/*"
                element={<ProtectedRoute element={<Admin />} />}
              />
            </Routes>
          </AxiosInterceptor>
        </Router>
      </ThemeProvider>
    </ConfigProvider>
  );
};

function App() {
  return (
    <Suspense fallback="loading">
      <Provider store={store}>
        <ProviderConfig />
      </Provider>
    </Suspense>
  );
}

export default App;
