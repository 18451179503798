import React from "react";
import propTypes from "prop-types";
import { useSelector } from "react-redux";

import { toConsentAction } from "./utility";
import { ApiCallReturnCodes } from "../config/api/ApiCallReturnCodes";

const AuthorizedRoute = ({ element }) => {
  const { isAuthorized, action } = useSelector((state) => {
    if (state.auth.consentsStatus) {
      if (state.auth.consentsStatus !== ApiCallReturnCodes.SUCCESS) {
        return {
          isAuthorized: false,
          actionRequired: toConsentAction(state.auth.consentsStatus),
        };
      }
      return {
        isAuthorized: true,
        actionRequired: null,
      };
    }
    return {
      isAuthorized: false,
      actionRequired: null,
    };
  });

  return isAuthorized ? (
    element
  ) : (
    <>{window.location.replace("/register_consents/" + action)}</>
  );
};

AuthorizedRoute.propTypes = {
  element: propTypes.object.isRequired,
};

export default AuthorizedRoute;
